import { React, useState, useEffect } from "react";
import "./App.css";
import Header from "./components/Header/Index";
import { AiOutlineMinus } from "react-icons/ai";
import { Link } from 'react-router-dom'

function App() {
  return (
    <div className="App">
      <Header />
      <section
        className="section"
        style={{
          backgroundImage: `url(${"https://colorlib.com/wp/wp-content/uploads/sites/2/computer-mockups.jpeg"})`,lineHeight: "29px", letterSpacing:"4px",boxSizing:"border-box", color:"white"
        ,}}
      >
        <div className="textContainer">
        <h2 style={{fontSize:"39px"}} >Lorem ipsum dolor sit amet, consectetur adipiscing</h2>
        <p style={{fontSize:"26px"}}>Reliable technical partner for more than 500 companies since 2006</p> </div>
      </section>
      <section  id="company" className="sectionCompany">
        <div className="company">
          <h2 >COMPANY</h2>
          <div>
            <p>
              PRIVAT Technology Services is a company founded in 2013, with the
              goal to supply the needs of software development for private and
              public sectors. For the past years the company has evolved into a
              broader spectrum delivering, app and website development, data
              services and technology consulting.
            </p>
          </div>
        </div>
        <div className="companyImg"></div>
      </section>
      <section id="industries" className="sectionTwo">
        <div className="divLeft">
          <h2>INDUSTRIES WE COVER</h2>
          <div>
            <AiOutlineMinus />
          </div>
          <div className="industries">
            <p>HEALTHCARE / SAÚDE</p>
          </div>
          <div className="industries">
            <p>MEDIA & ADVERTISING / Media & Propaganda e Marketing</p>
          </div>
          <div className="industries">
            <p>RETAIL & SERVICES / VENDAS E SERVIÇOS</p>
          </div>
          <div className="industries">
            <p>EDUCATION / EDUCAÇÃO</p>
          </div>
          <div className="industries">
            <p>ENTERPRISE SOFTWARE</p>
          </div>
          <div className="industries">
            <p>CONSTRUCTION / CONSTRUÇÃO CIVIL</p>
          </div>
          <div className="industries">
            <p>ENVIRONMENT / GREEN SOLUTIONS / MEIO AMBIENTE</p>
          </div>
          <div className="industries">
            <p>CYBER SECURITY / SEGURANÇA CIBERNÉTICA</p>
          </div>
        </div>

        <div className="divRight">
          <div className="img">
            <div />
          </div>
        </div>
      </section>
      <section
        className="section"
        style={{
          backgroundImage: `url(${"https://ak.picdn.net/shutterstock/videos/9597380/thumb/1.jpg"})`,
        }}
      >
        <div>
          <h2>ESTIMATION IS THE FIRST STEP TO SUCCESS!</h2>
        </div>
        <div className="buttons">
          <button className="footerButton">GET A QUOTE</button>
          <div className="or">OR</div>
          <Link style={{textDecoration:"none"}} to="/contact"><button className="footerButton">CONTACT US</button></Link>
        </div>
      </section>
    </div>
  );
}

export default App;
