import {React, useState, useEffect} from "react";
import "./header.css";
// import Bars from './Bars'
import BurgerMenu from "./BurgerMenu";
import App from "../../App";
import { Link } from 'react-router-dom'


const Header = () => {
  
    const [navbar, setNavbar] = useState(false)
    const changeBackground = () => {
        // console.log(window.scrollY)
        if (window.scrollY >= 66) {
          setNavbar(true)
        } else {
          setNavbar(false)
        }
      }
      useEffect(() => {
        changeBackground()
        // adding the event when scroll change background
        window.addEventListener("scroll", changeBackground)
      })
      useEffect(() => {
        const menuWrap = document.querySelector(".bm-menu-wrap");
        if (menuWrap) {
          menuWrap.setAttribute("aria-hidden", true);
        }
      }, []);
  return (
    <div className={navbar ? "navbar active" : "navbar"}>
      <Link style={{textDecoration:"none"}} to="/"><div className="logo"></div></Link>
      {/* <div className="bars"><VscThreeBars /></div> */}
     {/* <BurgerMenu right className="bars"/> */}
      <div className="menu">
        <ul>
        <a href="#industries" style={{textDecoration:"none", color:"white", display:"flex", justifyContent:"center"}}> <li>INDUSTRIES</li></a>
        <a href="#services" style={{textDecoration:"none",color:"white", display:"flex", justifyContent:"center"}}><li>SERVICES</li></a>
        <a href="#company" style={{textDecoration:"none", color:"white", display:"flex", justifyContent:"center"}}><li>COMPANY</li></a>
        <Link style={{textDecoration:"none"}} to="/contact"><li><button>CONTACT US</button></li></Link>
        </ul>
      </div>
     
    </div>
  );
};
export default Header;
