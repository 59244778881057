import { React, useState } from "react";
import "./App.css";
import { send } from "emailjs-com";
import Header from "./components/Header/Index";
import "./contact.css";
import TextField from "@material-ui/core/TextField";
import { Button } from "@material-ui/core";
import { BsTelephone } from "react-icons/bs";
import { AiOutlineMail } from "react-icons/ai";
import { GoLocation } from "react-icons/go";
import { IconContext } from "react-icons";
function Contact() {
  const [toSend, setToSend] = useState({
    from_name: "",
    to_name: "",
    message: "",
    reply_to: "",
  });

  const onSubmit = (e) => {
    e.preventDefault();
    send("SERVICE ID", "TEMPLATE ID", toSend, "User ID")
      .then((response) => {
        console.log("SUCCESS!", response.status, response.text);
      })
      .catch((err) => {
        console.log("FAILED...", err);
      });
  };

  const handleChange = (e) => {
    setToSend({ ...toSend, [e.target.name]: e.target.value });
  };
  return (
    <div className="App">
      <Header/>
      <div className="container">
        <div className="titleContainer">
          <div className="titleText">
        <h3>
          <span>C</span>
          <span>O</span>
          <span>N</span>
          <span>T</span>
          <span>A</span>
          <span>C</span>
          <span>T</span>
          <span> </span>
          <span>U</span>
          <span>S</span>
        </h3></div></div>
        <div className="contactLeft">
          <IconContext.Provider value={{ color: "#0F0F49" }}>
            {" "}
            <div className="iconContainer">
              <GoLocation size="27px" />
              <p>São Paulo - Brazil</p>
            </div>
            <div className="iconContainer">
              {" "}
              <BsTelephone size="27px" /> <p>BR (+55) (99) 9999-999</p>
            </div>
            <div className="iconContainer">
              {" "}
              <AiOutlineMail size="27px" />
              <p>example@example.com.br</p>
            </div>
          </IconContext.Provider>
        </div>
        <div className="contactRight">
          <h2>CONTACT US</h2>
          <form onSubmit={onSubmit}>
            <div className="formContainer">
              <TextField
                type="text"
                name="from_name"
                id="standard-basic"
                label="Name *"
                variant="standard"
                value={toSend.from_name}
                onChange={handleChange}
              />{" "}
            </div>{" "}
            <div className="formContainer">
              <TextField
                type="text"
                name="reply_to"
                value={toSend.reply_to}
                onChange={handleChange}
                id="standard-basic"
                label="Your email *"
                variant="standard"
              />{" "}
            </div>
            <div className="formContainer">
              <TextField
                type="text"
                name="message"
                value={toSend.message}
                onChange={handleChange}
                id="standard-basic"
                label="Your message"
                variant="standard"
              />
            </div>
            <Button
              type="submit"
              variant="contained"
              style={{
                backgroundColor: "blue",
                borderRadius: "50px",
                width: "200px",
                fontWeight: "bold",
                margin: "20px",
              }}
            >
              Send
            </Button>
            {/* <button type='submit'>Submit</button> */}
          </form>
        </div>
      </div>
    </div>
  );
}

export default Contact;
{
  /* <div */
}
//     style={{
//       display: "flex",
//       justifyContent: "space-between",
//       alignItems: "center",
//       height: "100vh",
//       width: "100%",
//       boxSizing: "border-box",
//     }}
//   >
//   <IconContext.Provider value={{ color: "#0F0F49", }}><div style={{
//     display: "flex", justifyContent:"center", alignItems:"flex-start", marginLeft:"50px",flexDirection:"column", height: "100vh", width: "50%",}}>
//     <div style={{marginBottom:"50px"}} ><GoLocation size="27px" /></div>
//     <div style={{marginBottom:"50px"}} ><BsTelephone size="27px"/></div>
//     <div style={{marginBottom:"20px"}} ><AiOutlineMail size="27px"/></div></div></IconContext.Provider>
//     <div
//       style={{
//         display: "flex",
//         justifyContent: "center",
//         alignItems: "flex-start",
//         flexDirection: "column",
//         flexWrap: "wrap",
//         width: "50%",
//         height: "100vh",
//         marginRight:"50px"
//       }}
//     >
//       <h2 >CONTACT US</h2>
//       <div
//         style={{

//           width: "100%",
//           display: "flex",
//         //   justifyContent: "space-around",
//           alignItems:"flex-start"
//         }}
//       >
//         <TextField
//           id="standard-basic"
//           label="Name *"
//           variant="standard"
//           helperText=" Please enter your name "
//         />
//         <TextField
//           id="standard-basic"
//           label="Last Name *"
//           variant="standard"
//         />
//       </div>
//       <div
//         style={{
//           width: "100%",
//           display: "flex",
//           marginTop: "50px",
//         //   justifyContent: "space-around",
//         }}
//       >
//         <TextField id="standard-basic" label="Email *" variant="standard" />
//         <TextField
//           id="standard-basic"
//           inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
//           label="Phone"
//           variant="standard"
//         />
//       </div>
//       <div
//         style={{
//           width: "100%",
//           marginTop: "50px",
//           justifyContent: "center",
//         }}
//       >
//         {" "}
//         <TextField
//           id="standard-basic"
//           label="Your message"
//           fullWidth
//           variant="standard"
//         />
//       </div>
//       <div style={{marginTop:"50px"}}>
//       <Button variant="contained" style={{backgroundColor:"blue", borderRadius:"50px", width:"200px", fontWeight:"bold"}}>
//         Send
//       </Button>
//       </div>
//     </div>
//   </div>
// </div>
